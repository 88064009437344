import { Grid, Image, Text, Title } from '@mantine/core';
import React, { useState } from 'react';
import productionIntro from '../videos/production-intro.mp4';
import factory from '../videos/factory.mp4';
import pollution from '../images/pollution.png';
import explosion from '../images/explosion.png';
import highCost from '../images/high-cost.png';
import pollutionInverse from '../images/pollution-inverse.png';
import explosionInverse from '../images/explosion-inverse.png';
import highCostInverse from '../images/high-cost-inverse.png';
import step1 from '../images/step1.svg';
import step2 from '../images/step2.svg';
import step3 from '../images/step3.svg';
import step4 from '../images/step4.svg';
import step5 from '../images/step5.jpg';
import stack from '../images/stack.png';
import productionLine from '../images/production-device.jpg';
import noStore from '../images/no-store.svg';
import safe from '../images/safe.svg';
import greenTech from '../images/green-tech.svg';
import lowerCost from '../images/lower-cost.svg';
import modular from '../images/modular.svg';
import './product.less';
import { motion } from 'framer-motion';
import SEO from "../components/seo";

const dangers = [
  { name: 'High Pollution', image: pollution, imageInverse: pollutionInverse },
  { name: 'High Explosion Risk', image: explosion, imageInverse: explosionInverse },
  { name: 'High Cost', image: highCost, imageInverse: highCostInverse }
];

const steps = [
  { name: 'Hydrogenation', image: step1, width: '19%' },
  { name: 'Oxidation', image: step2, width: '19%' },
  { name: 'Extraction', image: step3, width: '19%' },
  { name: 'Refinement and Purification', image: step4, width: '24%' },
  { name: 'Delivery to Clients', image: step5, width: '19%' },
];

const advantages = [
  { icon: noStore, description: 'Water, Electricity, and Oxygen are immediately used for production', tag: 'Storage is unnecessary' },
  { icon: safe, description: 'Stable, consistent product strength', tag: 'Safe process' },
  { icon: greenTech, description: 'No Byproducts', tag: 'Green Technology' },
  { icon: lowerCost, description: '20-40%', tag: 'Cost Reduction' },
  { icon: modular, description: 'Flexible production based on site specific needs', tag: 'Modular System' },
];

function Product() {
  const [play, setPlay] = useState<boolean>(false);

  return (<div className="product">
    <section className="bg">
      <video src={factory} autoPlay loop muted></video>
      <div className='text-tag'>Our new electrochemical hydrogen peroxide on-site production equipment has redefined the conventional synthesis method for manufacturing hydrogen peroxide.</div>
    </section>
    <section className="production-process">
      <Title className='title' order={1}>How Hydrogen Peroxide is traditionally produced</Title>
      <Title className='sub-title' order={2}>Anthraquinone Process</Title>
      <div className='list'>
        {dangers.map(danger => (
          <motion.div
            key={danger.name}
            whileHover={{
              backgroundColor: '#37acbf',
              color: '#fff'
            }}
            className="item"
          >
            <img className='image-normal' src={danger.image} />
            <img className='image-inverse' src={danger.imageInverse} />
            <span>{danger.name}</span>
          </motion.div>
        ))}
      </div>
      <Text className='image-description'>Multi-step Reactions in organic solvents</Text>
      <div className="steps-container">
        {steps.map(({ name, image, width }, index) => (
          <div key={index} className="step-container" style={{ width }}>
            {index === 4 && <Text className="step5-description">27.5%、50%<br />Transportation of Highly Concentrated Chemicals</Text>}
            <img src={image} />
            <Text className="step-name">{name}</Text>
          </div>
        ))}
      </div>
    </section>
    <section className="production-device">
      <Title id="tech" className='title' order={1}>The advanced technology developed by Phase Two Chemicals has made on-site electrochemical production a reality.</Title>
      <Text className='description'>On-site electrochemical hydrogen peroxide production has been studied academically and industrially for decades; however, the efficiency was low which resulted in uncompetitive costs and unfruitful commercialization attempts. Phase Two Chemicals has improved the electrocatalysis, electrode design and manufacturing, and stack design substantially. These advances have allowed electrochemical hydrogen peroxide synthesis to be cost-competitive with the traditional anthraquinone process.</Text>
      <div style={{ textAlign: 'center' }}>
        <motion.img
          className='stack'
          src={stack}
          whileHover={{ scale: 1.2 }}
          transition={{ duration: 3 }}
        />
      </div>
    </section>
    <section className="production-device-intro">
      <Title className='title' order={1}>Introduction to Phase Two Chemicals’ on-site hydrogen peroxide production equipment</Title>
      <div className='video-container' onClick={() => setPlay(true)}>
        <video src={productionIntro} preload="metadata"></video>
      </div>
    </section>
    {play && <div className="video-mask">
      <div className="video-container">
        <video src={productionIntro} autoPlay controls></video>
        <span onClick={() => setPlay(false)} className="close-btn"></span>
      </div>
    </div>}
    <section className="production-line">
      <div className="title-container">
        <Title className='sub-title' order={2}>Phase Two Chemicals’ on-site electrochemical hydrogen peroxide production equipment</Title>
        <Title className='title' order={1}>integrates easily into existing industrial peroxide processes</Title>
      </div>
      <motion.img
        src={productionLine}
        whileHover={{ scale: 1.1 }}
        transition={{ duration: 3 }}
      />
    </section>
    <section className="advantages">
      <Title className='title' order={1}>Advantages</Title>
      <div className="list">
        {advantages.map(advantage => (
          <div key={advantage.tag} className='item'>
            <img src={advantage.icon} />
            <Text className='description'>{advantage.description}</Text>
            <Text className='tag'>{advantage.tag}</Text>
          </div>
        ))}
      </div>
    </section>
  </div>);
}

export default Product;

export const Head = () => (<SEO />)
